import React, { Fragment, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import GradeIcon from '@material-ui/icons/Grade';
const grades = [
  '1',
  '2',
  '3',
  '4',
  '4+',
  '5',
  '5+',
  '6a',
  '6a+',
  '6b',
  '6b+',
  '6c',
  '6c+',
  '7a',
  '7a+',
  '7b',
  '7b+',
  '7c',
  '7c+',
  '8a',
  '8a+',
  '8b',
  '8b+',
  '8c',
  '8c+',
  '9a',
]
const GradeSelect = ({ grade, handleCancel, handleSave }) => {
  const [open, setOpen] = useState(false);
  const onHandleCancel = () => {
    if ( handleCancel ) {
      handleCancel();
    }
    setOpen(false);
  }
  const onHandleSave = (grade) => {
    handleSave(grade);
    setOpen(false);
  }

  return (
    <Fragment>
      <Button variant="outlined" color="primary" onClick={setOpen}>
        {grade ? <CheckIcon /> : <GradeIcon />} Select Grade
      </Button>
    
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{grade ? <span>Selected</span> : <span>Select</span>} Grade {grade ? <span>{grade}</span> : <span>(Choose wisely)</span>}</DialogTitle>
        <DialogContent>
          <List>
            {grades.map((item) => {
              return (
                <ListItem 
                  button
                  selected={item === grade}

                  onClick={() => onHandleSave(item)}
                  key={item}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <GradeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item} />
                </ListItem>
              )
            })}
          
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHandleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onHandleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default GradeSelect;