import React, { Fragment, useState } from 'react';
import { withFirebase } from './index';

const FirebaseImage = ({ fullPath, firebase }) => {
  // TODO: set loading and error states so we can show a pretty spinner
  const [url, setUrl] = useState(null);
  firebase.getDownloadUrl(fullPath).then(url => {
    setUrl(url)
  })
  // TODO: Add lazy loading? loading="lazy"
  return (
    <Fragment>
      {url &&
        <img src={url} style={{width: '100%', height:'auto'}} />
      } 
   </Fragment>
  )
}

export default withFirebase(FirebaseImage);