import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, Grid, Container } from '@material-ui/core';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div>
    
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Container maxWidth="md">
      <form onSubmit={this.onSubmit}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item md={true} sm={true} xs={true}>
            <h1>Password forget</h1>
            <TextField 
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              label="Email Address"
              autoFocus
              fullWidth 
              required
            />
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: '10px' }}>
          <Button 
            type="submit"
            variant="contained" 
            color="primary"
            disabled={isInvalid}
          >
            Reset My Password
          </Button>
          {error && <p>{error.message}</p>}
        </Grid>

        {/* <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        /> */}
        {/* <button disabled={isInvalid} type="submit">
          Reset My Password
        </button> */}

        {error && <p>{error.message}</p>}
      </form>
      </Container>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
