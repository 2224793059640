import React, {useState} from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { withCurrentPosition } from '../../components/CurrentPosition';
import { CONTAINER_STYLES, MAP_OPTIONS, DEFAULT_ZOOM, MAPS_API_KEY } from './const';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const GoogleMaps = ({
  currentPosition,
  children,
  onMapsLoaded,
  onDragEnd,
  onZoomChanged,
  setCurrentPosition
}) => {
  const [map, setMap] = React.useState(null)
  const [loaded, setLoaded] = useState(false);
  const [currentCenter, setCenter] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleOnDragEnd = () => {
    // setCurrentPosition({lat: map.center.lat(), lng: map.center.lng()});
    if ( onDragEnd ) {
      onDragEnd()
    }
  }
  const handleOnMapsLoaded = (map) => {
    setMap(map);
    if (onMapsLoaded ) {
      onMapsLoaded(map);
    }
  }

  const handleOnZoomChanged = () => {
    if (onZoomChanged) {
      onZoomChanged()
    }
  }

  return (
    <LoadScript
      googleMapsApiKey={MAPS_API_KEY}
      onLoad={() => setLoaded(true)}
    >
      <GoogleMap
        className="googleMap"
        mapContainerStyle={{
          width: '100%',
          height: matches ? `calc(100vh -  69px)`: `calc(100vh -  0px)`, //'400px'
          zIndex: 1,
          maxHeight: '100vh',
        }}
        center={currentPosition}
        zoom={DEFAULT_ZOOM}
        onZoomChanged={() => {handleOnZoomChanged()}}
        onLoad={(map) => {handleOnMapsLoaded(map)}}
        onDragEnd={() => {handleOnDragEnd()}}
        options={{
          gestureHandling: matches ? 'greedy' : 'cooperative',
          ...MAP_OPTIONS
        }}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  )
}
export default withCurrentPosition(React.memo(GoogleMaps));
