import React from 'react';

const CurrentPositionContext = React.createContext(null);

export const withCurrentPosition = Component => props => {
  return (
    <CurrentPositionContext.Consumer>
      {positionProvider => positionProvider ? <Component {...props} positionProvider={positionProvider} setCurrentPosition={positionProvider.setCurrentPosition} currentPosition={positionProvider.currentPosition} /> : null}
    </CurrentPositionContext.Consumer>
  )
}

export default CurrentPositionContext;