export const MAX_ZOOM = 18;
export const MAPS_API_KEY = "AIzaSyCaLGoIpLg8SxLLuJgYXTW5mJkdo7WV_f8";
export const CONTAINER_STYLES = {
  width: '100%',
  height: '400px',
  maxHeight: '100vh',
};
export const DEFAULT_ZOOM = 13;
export const MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  minZoom: 3
};