import React, { useState, useContext, useEffect } from "react";
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { TextField, Button, Grid, Container } from '@material-ui/core';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { withFirebase } from '../../components/Firebase';
import { withSnackbar } from "../../components/Snackbar";
import PlaceMarkerFullScreen from '../../components/MapsComponent/PlaceMarkerFullScreen';
import Editor from '../../components/ImageEditor';
import GradeSelect from '../../components/GradeSelect';
import { withEmailVerification } from '../../components/Session';

const RouteDetail = ({ snackbar, history, firebase, match: { params } }) => {
  const authUser = useContext(AuthUserContext);
  const [route, setRoute] = useState(null);
  const [isUploading, setIsUplading] = useState(false);
  const [grade, setGrade] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [position, setPosition] = useState(null);
  const [images, setImages] = useState(null);

  const isInvalid = name === '' || grade === '' || position === null || images === null || isUploading === true;

  useEffect(() => {
    if (params.uid, authUser) {
      firebase
        .myRoute(params.uid)
        .get()
        .then(doc => {
          console.log(doc.data())
          setRoute(doc.data())
          setName(doc.data().d.name)
          setDescription(doc.data().d.description ? doc.data().d.description : '')
          setGrade(doc.data().d.grade)
          setPosition({lat: doc.data().d.lat, lng: doc.data().d.lng})
          setImages({
            holds: doc.data().d.holds ? doc.data().d.holds : null , 
            imageRef: doc.data().d.image ? doc.data().d.image : null,
            originalImageRef: doc.data().d.originalImageRef ? doc.data().d.originalImageRef : null,
          });
          console.log(doc.data().d)
        });
    }
  }, [params, firebase, params.uid, authUser])

  const updateRoute = () => {
    const { ...messageSnapshot } = route.d;
    const d = {
      ...messageSnapshot,
      name,
      grade,
      description,
      editedAt: firebase.fieldValue.serverTimestamp(),
    }
    console.log(d);
    route.d = d;
    firebase.myRoute(params.uid).update(route).then(() => {
      snackbar.setSnack('Thanks! your route has been updated');
      history.push('/my-routes');
    });
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={0}
        justify="center"
      >
        <Grid item xs={12}>
          <h1>Edit your route</h1>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
      >
        <Grid item xs={12}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            name="name"
            value={name}
            onChange={(event) => { console.log(event.target); setName(event.target.value)}}
            type="text"
            label="Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="description"
            label="Tell something about the boulder"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            type="text"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{marginTop: '40px'}}>
          <PlaceMarkerFullScreen 
            position={position} 
            handleSave={(position) => {setPosition(position)}}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: '40px'}}>
          <GradeSelect 
            grade={grade}
            handleSave={(grade) => {setGrade(grade)}}
          />
        </Grid>
      </Grid>
      
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}  style={{marginTop: '40px'}}>
          {/* <Editor 
            boulder={images}
            handleSave={(images) => {setImages(images)}}
          /> */}
          <p>Image update coming soon!</p>
        </Grid>
      </Grid> 

      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={updateRoute}
            disabled={isInvalid}
            style={{marginTop: '40px'}}
          >
            Update route
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
};

// withFirebase();
export default compose(
  withRouter,
  withFirebase,
  withSnackbar,
  withEmailVerification
)(RouteDetail); 