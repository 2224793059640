import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TopMenu from './layouts/AppBar/TopMenu';
import SideMenu from './layouts/SideMenu';
import { authenticatedRoutes, unauthenticatedRoutes } from './routes';
import { withAuthentication } from './components/Session';
import './App.css';

const App = () => {

  // TODO: Set up palette colors for theme
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#303030',
      },
      secondary: {
        main: 'rgba(0, 0, 0, 0.54)'
      },
      white: {
        main: '#fff'
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <div>
        <TopMenu />
        <main>
          <Switch>
            {authenticatedRoutes.map(({ path, component }) => (
              <Route key={path} exact path={path} component={component} />
            ))}
          </Switch>
          <Switch>
            {unauthenticatedRoutes.map(({ path, component }) => (
              <Route key={path} exact path={path} component={component} />
            ))}
          </Switch> 
        </main>
      </div>
    </ThemeProvider>
  );  
}
export default compose(
  withAuthentication,
)(App);
