import React, { useContext, Fragment } from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';


import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { IconButton, ListItemIcon } from '@material-ui/core/';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import SignOutListItem from '../components/Signout/SignOutListItem';
import AppContext from '../context/AppContext';
import { AuthUserContext } from '../components/Session';

import ExploreIcon from '@material-ui/icons/Explore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
   
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const menuItems = [
  {text: 'Explore', icon: <ExploreIcon />, to: '/'},
];
const authMenuItems = [
  {text: 'Dashboard', icon: <DashboardIcon />, to: '/dashboard'},
  {text: 'My routes', icon: <ListIcon />, to: '/my-routes'},
  {text: 'Set route', icon: <AddCircleIcon />, to: '/set-route'},
];

export default function TemporaryDrawer() {
  const authUser = useContext(AuthUserContext);
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuOpen(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem 
            button 
            key={item.to}
            component={Link}
            to={item.to}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        {!authUser && 
          <ListItem 
            button 
            key={'set-route'}
            component={Link}
            to="signin"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ListItemIcon><AddCircleIcon /></ListItemIcon>
            <ListItemText primary="Set route" />
          </ListItem>
        }
      
        <Divider />

        {authUser && authMenuItems.map((item, index) => (
          <ListItem 
            button 
            key={item.to}
            component={Link}
            to={item.to}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        
        <Divider />
        <ListItem 
            button 
            key={'privacy'}
            component={Link}
            to="/privacy"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ListItemIcon><LockIcon /></ListItemIcon>
            <ListItemText primary="Privacy policy" />
          </ListItem>
          <ListItem 
            button 
            key={'contact'}
            component={Link}
            to="/contact"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ListItemIcon><ContactSupportIcon /></ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItem>

          {authUser &&
            <>
            <Divider />
            <SignOutListItem 
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            />
            </>
          }
      </List>
    </div>
  );

  return (
    <React.Fragment key={'left'}>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor={'left'} open={menuOpen} onClose={toggleDrawer(false)}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer(false)}>
            { <ChevronLeftIcon /> }
          </IconButton>
        </div>
        {list()}
      </Drawer>
    </React.Fragment>
  );
}