import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { TextField, Button, Grid, Container } from '@material-ui/core';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <div>
    
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    const roles = {};

     this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase.profile(authUser.user.uid).set(
          {
            username,
          },
          { merge: true },
        ).then(() => {
          this.props.firebase.user(authUser.user.uid).set(
            {
              username,
              email,
              roles,
            },
            { merge: true },
          );
        });
      })
      
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/dashboard');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <Container maxWidt="md">
      <form onSubmit={this.onSubmit}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={12}>
            <h1>SignUp</h1>
            <TextField 
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              label="Full name"
              fullWidth
              autoFocus
              required
              autocapitalize={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={12}>
            <TextField 
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              label="Email Address"
              fullWidth
              required
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={12}>
            <TextField 
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              label="Password"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={12}>
            <TextField 
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              label="Confirm Password"
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: '10px' }}>
          <Button 
            type="submit"
            variant="contained" 
            color="primary"
            disabled={isInvalid}
          >
            Sign Up
          </Button>
          {error && <p>{error.message}</p>}
        </Grid>
      </form>
      </Container>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
