import React, { Fragment } from 'react';
import { Grid, Container } from '@material-ui/core';

const Privacy = () => (
  <Container maxWidth="md">
    <Grid
      container
      spacing={0}
      direction="column"
      justify="center"
    >
      <Grid item xs={12}>
        <h1>Privacy policy</h1>
        <p>
          Something about the privacy. We don't track your data. We don't have any commercial interest in your data. We only use it to give you the best experience possible.
        </p>
      </Grid>  
    </Grid> 
  </Container>
  
);

export default Privacy;
