import React, { Fragment } from 'react';
import { Grid, Container } from '@material-ui/core';

const Contact = () => (
  <Container maxWidth="md">
    <Grid
      container
      spacing={0}
      direction="column"
      justify="center"
    >
      <Grid item xs={12}>
        <h1>Contact</h1>
        <p>
          Questions? Feedback? Feature requests? Missing something? Want to help improve streetboulders.com? Or just want to say something nice? 
        </p>
        <p>
          <a href="mailto:peter@streetboulders.com">Send me an email!</a>
        </p>
      </Grid>  
    </Grid> 
  </Container>
  
);

export default Contact;
