import React, { useEffect, useState, Fragment, useContext } from 'react';

import {Button, Dialog, Paper, Grid, Slide, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GoogleMaps  from './GoogleMaps';
import CurrentPositionButton from '../CurrentPosition/CurrentPositionButton';
import CheckIcon from '@material-ui/icons/Check';
import RoomIcon from '@material-ui/icons/Room';
import { CurrentPositionContext } from '../CurrentPosition/';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlaceMarkerFullScreen = ({ position, handleCancel, handleSave }) => {
  const positionProvider = useContext(CurrentPositionContext);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [open, setOpen] = useState(false);
  const background = '/images/spotlight-poi2.png';

  useEffect(() => {
    if (position && position.lat !== undefined && position.lng !== undefined) {
      console.log({position})
      positionProvider.setCurrentPosition(position)
    }
  }, [position]);

  const onDragEnd = () => {
    if (map) {
      setCenter(map.getCenter());
    }
  }

  const onMapsLoaded = (map) => {
    setCenter(map.getCenter());
    setMap(map);
  }

  const handleOnCancel = () => {
    setOpen(false);
    if ( handleCancel ) {
      handleCancel();
    }
  }
  
  const onHandleSave = () => {
    if (center) {
      handleSave({lat: center.lat(), lng: center.lng()});
      setOpen(false);
    }
  }

  return (
    <Fragment>
      <Button variant="outlined" color="primary" onClick={setOpen}>
        {position && <CheckIcon /> }  Place marker on map <RoomIcon />
      </Button>
      <Dialog fullScreen open={open} onClose={handleOnCancel} TransitionComponent={Transition}>
        <Paper square style={{zIndex: 3}}>
          <Grid container spacing={2} direction="row" justify="space-between" alignItems="stretch">
            <Grid item>
              <IconButton color="inherit" onClick={handleOnCancel} aria-label="close"><CloseIcon /></IconButton>
            </Grid>
            <Grid item>
              <CurrentPositionButton />
            </Grid>
            <Grid item>
              <Button style={{paddingTop: '12px'}} autoFocus color="inherit" onClick={onHandleSave}>save</Button>
            </Grid>
          </Grid>
        </Paper>
        
        <GoogleMaps
          onMapsLoaded={(map) => onMapsLoaded(map)}
          onDragEnd={onDragEnd}
        >
          <div style={{
            position:'relative',
            background: `url(${background}) no-repeat`,
            top:'50%',
            left:'50%',
            zIndex:3,
            marginLeft:'-13.5px',
            marginTop:'-43px',
            height:'43px',
            width:'27px',
            cursor:'pointer',
          }}/>
        </GoogleMaps>
      </Dialog>
    </Fragment>
  );
}

export default PlaceMarkerFullScreen;