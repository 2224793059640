import React, { useState, Fragment } from 'react';
import { MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import { MAX_ZOOM } from './const';
import GoogleMaps from './GoogleMaps';
import { Circle } from '@react-google-maps/api';
import { withCurrentPosition } from '../CurrentPosition';
import { DEFAULT_ZOOM } from './const';

// TODO: Cleanup unused states
const ClusterMap = ({ setCurrentPosition, currentPosition, firebase, routes, onClusterClick, onMarkerClick, routeSelected }) => {
  const [ selectedRoute, setSelectedRoute ] = useState(null);
  const [ selectedCluster, setSelectedCluster ] = useState(null);
  const [ selectedMarker, setSelectedMarker ] = useState(null);
  const [ routesToShow, setRoutesToShow ] = useState(null);
  const [ routeToShow, setRouteToShow ] = useState(null);
  const [ selectedCoordinates, setSelectedCoordinates ] = useState(null);
  const [ circle, setCircle ] = useState(null);
  const [ map, setMap ] = useState(null);
  const [ currentZoom, setCurrentZoom ] = useState(DEFAULT_ZOOM);
  
  const options = {
    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    averageCenter: true,
    maxZoom: MAX_ZOOM
  };

  const clusterToRoutes = (cluster) => {
    const clusterRoutes = [];
    cluster.markers.forEach(marker => {
      const item = routes.filter(route => route.uid === marker.title);
      clusterRoutes.push(item[0]);
    });
    return clusterRoutes;
  }

  const handleClusterClick = (cluster) => {
    setSelectedCluster(cluster);
    //https://stackoverflow.com/questions/3548920/google-maps-api-v3-multiple-markers-on-exact-same-spot
    const zoom = cluster.map.getZoom();
    const maxZoom = cluster.markerClusterer.getMaxZoom();
    const center = cluster.center;
    // // if we have reached the maxZoom and there is more than 1 marker in this cluster
    // // use our onClick method to popup a list of options
    if (zoom >= maxZoom && cluster.markers.length > 1) {
      setSelectedCoordinates({lat: center.lat(), lng: center.lng()})
      showRoutes(clusterToRoutes(cluster));
    } else {
      // continue zooming
      const newZoom = zoom + 1;
      cluster.map.setZoom(newZoom);
      cluster.map.setCenter({lat: center.lat(), lng: center.lng()})
    }
  }

  const handleMarkerClick = (e, route) => {
    setSelectedCoordinates({lat: route.lat, lng: route.lng})
    setSelectedMarker(e);
    showRoutes([route]);
  }

  const showRoutes = (routes) => {
    setRoutesToShow(routes);
    onClusterClick(routes);
    return true;
  }

  const handleInfoWindowClose = () => {
    setSelectedCluster(null);
    setSelectedRoute(null);
    setRoutesToShow(null);
    // hideRoute();
  }

  const createMarker = (route, clusterer) => {
    const marker = (<Marker
      key={route.uid} 
      position={{lat: route.coordinates.latitude, lng: route.coordinates.longitude}} 
      clusterer={clusterer} 
      onClick={(e) => handleMarkerClick(e, route)}
      title={route.uid}
      name={route.uid}
      metadata={{
        uid: route.uid
      }}
    />)
    return marker;
  }

  const handleDragEnd = () => {
    if (circle && map && map.zoom >= 7) {
      console.log('SETTING CURRENT POSITION FROM DRAG')
      const bounds = circle.getBounds();
      if (!bounds.contains(map.center)) {
        setCurrentPosition({lat: map.center.lat(), lng: map.center.lng()});
      }
    }
  }

  const handleZoomChanged = () => {
    if (circle && map) {
      const bounds = circle.getBounds();
      if (!bounds.contains(map.center) && map.zoom > currentZoom && map.zoom > 7) {
        setCurrentPosition({lat: map.center.lat(), lng: map.center.lng()});
      }
      setCurrentZoom(map.zoom);
    }
  }

  const onLoad = (circle) => {
    setCircle(circle);
  };

  return (
    <Fragment>
      <GoogleMaps
        onMapsLoaded={setMap}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChanged}
      >
      {routes !== null && routes !== undefined && 
        <MarkerClusterer 
          options={options}
          onClick={(e) => handleClusterClick(e)}
          zoomOnClick={false}
        >
          {clusterer =>
            routes.map(route => (
              createMarker(route, clusterer)
            ))
          }
        </MarkerClusterer>
      }

      <Circle
        center={currentPosition}
        radius={150000}
        onLoad={onLoad}
        options={{
          fillColor: '#ff00ff',
          strokeWeight: 0,
          fillOpacity: 0.10,
        }}
      />
      {selectedCoordinates && 
        (
          <InfoWindow
            position={{lat: Number(selectedCoordinates.lat), lng: Number(selectedCoordinates.lng)}}
            clickable={true}
            onCloseClick={() => handleInfoWindowClose()}
          >
            <p>You are here!</p>
          </InfoWindow>
        )
      } 
      </GoogleMaps>
    </Fragment>
  )
}

// const ClusterMap = (props) => (
//   <Fragment>
//     <GoogleMaps children={<ClusterMapWrapped {...props} />} />
//   </Fragment>
// );
export default withCurrentPosition(ClusterMap);
