import React, { useState } from 'react';
import { CurrentPositionContext } from '.';


const CurrentPositionProvider = ({ children }) => {
  const defaultPosition = {lat: 52.0716335, lng: 4.2398292}

  const [currentPosition, onSetCurrentPosition] = useState({lat: 52.0716335, lng: 4.2398292});
  const [currentPositionError, onSetCurrentPositionError] = useState(false);
  const [currentPositionLoading, onSetCurrentPositionLoading] = useState(false);

  const setCurrentPosition = (position) => {
    onSetCurrentPosition(position)
  }
  const setCurrentPositionLoading = (loading) => {
    onSetCurrentPositionLoading(loading)
  }
  const setCurrentPositionError = (error) => {
    onSetCurrentPositionError(error)
  }
  return (
    <CurrentPositionContext.Provider value={{
      setCurrentPositionLoading,
      currentPositionLoading,
      setCurrentPositionError,
      currentPositionError,
      setCurrentPosition,
      currentPosition,
    }}>
      {currentPositionLoading && <p>loading current position...</p>}
      {currentPositionError && <p>Could not retrieve your current position...</p>}
      {children}
    </CurrentPositionContext.Provider>
  );
};

export default CurrentPositionProvider;
