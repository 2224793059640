import React from 'react';

const SnackbarContext = React.createContext(null);

export const withSnackbar = Component => props => {
  return (
    <SnackbarContext.Consumer>
      {snackbar => <Component {...props} snackbar={snackbar} />}
    </SnackbarContext.Consumer>
  )
}

export default SnackbarContext;