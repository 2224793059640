import React, { useState, useContext } from "react";
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { TextField, Button, Grid, Container } from '@material-ui/core';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { withFirebase } from '../../components/Firebase';
import { withSnackbar } from "../../components/Snackbar";
import PlaceMarkerFullScreen from '../../components/MapsComponent/PlaceMarkerFullScreen';
import Editor from '../../components/ImageEditor';
import GradeSelect from '../../components/GradeSelect';
import { withEmailVerification } from '../../components/Session'

const SetRoute = ({ history, firebase, snackbar }) => {
  const authUser = useContext(AuthUserContext);
  const [isUploading, setIsUplading] = useState(false);
  const [grade, setGrade] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [position, setPosition] = useState(null);
  const [images, setImages] = useState(null);

  const isInvalid = name === '' || grade === '' || position === null || images === null || isUploading === true;

  const createRoute = () => {
    setIsUplading(true);
    const route = {
      name: name,
      description: description,
      grade: grade,
      image: images.edited,
      // original: images.original,
      holds: images.holds,
      userId: authUser.uid,
      createdAt: firebase.fieldValue.serverTimestamp(),
      lat: position.lat,
      lng: position.lng
    }
    console.log({route});
    firebase.addRoute(route).then(() => {
      setIsUplading(false);
      snackbar.setSnack('Thanks! your route has been saved');
      history.push('/my-routes');
    });
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={0}
        justify="center"
      >
        <Grid item xs={12}>
          <h1>Set your route</h1>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
      >
        <Grid item xs={12}>
          <TextField 
            InputLabelProps={{ shrink: true }}
            name="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            label="Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="description"
            label="Tell something about the boulder"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            type="text"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{marginTop: '40px'}}>
          <PlaceMarkerFullScreen 
            position={position}
            handleSave={(position) => {setPosition(position)}}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: '40px'}}>
          <GradeSelect 
            grade={grade}
            handleSave={(grade) => {setGrade(grade)}}
          />
        </Grid>
      </Grid>
      
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}  style={{marginTop: '40px'}}>
          <Editor
            boulder={images}
            handleSave={(images) => {setImages(images)}}
          />
        </Grid>
      </Grid> 

      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={createRoute}
            disabled={isInvalid}
            style={{marginTop: '40px'}}
          >
            Save route
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default compose(
  withRouter,
  withFirebase,
  withSnackbar,
  withEmailVerification
)(SetRoute); 