import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Grid, Button, Container } from '@material-ui/core';
import { withFirebase, FirebaseImage } from '../../components/Firebase';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { withEmailVerification } from '../../components/Session'

const MyRouteDetail = ({ firebase, match: { params } }) => {
  const [route, setRoute] = useState(null);
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    if (params.uid, authUser) {
      firebase
        .myRoute(params.uid)
        .get()
        .then(doc => {
          setRoute(doc.data().d)
        });
    }
  }, [params, firebase, params.uid, authUser])

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="right"
        justify="center"
      >
        <Grid item xs={12}>
          <h1>My Route detail</h1>
          <Button
            color="primary"
            component={Link}
            variant="contained"
            to="/my-routes"
          >
            Back
          </Button>

          {route && 
            <Fragment>
              <p>{route.name}</p>
              <p>{route.grade}</p>
              <p>{route.description && route.description}</p>
              <FirebaseImage fullPath={route.image} />
            </Fragment>
          }
        </Grid>  
      </Grid> 
    </Container>
  )
};

export default compose(
  withFirebase,
  withEmailVerification
)(MyRouteDetail);
