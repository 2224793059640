import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './App';
import Firebase, { FirebaseContext } from './components/Firebase';
import { ContextProvider } from './context/AppContext.js';
import SnackbarProvider from './components/Snackbar';
import CurrentPositionProvider from './components/CurrentPosition';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <SnackbarProvider>
      <CurrentPositionProvider>
        <ContextProvider>
          <BrowserRouter>
              <App />
          </BrowserRouter>
        </ContextProvider>
      </CurrentPositionProvider>
    </SnackbarProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
