import React, { useState, Fragment } from 'react';
import { CardMedia } from '@material-ui/core';
import Spinner from '../Spinner';

import { withFirebase } from '../Firebase/context'

const FirebaseMediaCard = ({ className, fullPath, firebase, title }) => {
  const [url, setUrl] = useState(null);

  firebase.getDownloadUrl(fullPath)
  .then(url => {
    setUrl(url)
  }).catch(err => {
  });
  
  return (
    <Fragment>
      {url ? (
      <CardMedia
        className={className}
        image={url}
        title={title}
      />
      ) : (
        <Spinner loading={true} />
      )
    }
    </Fragment>
  )
}
export default withFirebase(FirebaseMediaCard);