import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { GeoFirestore } from 'geofirestore';
import { v4 as uuidv4 } from 'uuid';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    this.state = {
      authUser: null
    }

    /* Initialize */
    firebase.initializeApp(config);

    /* Helper */
    this.fieldValue = firebase.firestore.FieldValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storageRef = firebase.storage().ref();
    this.geofirestore = new GeoFirestore(this.db);

    /* Social Sign In Method Provider */
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // // default empty roles
            // if (!dbUser.roles) {
            //   dbUser.roles = {};
            // }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  
  // *** Public profiles API ***

  profile = uid => this.db.doc(`profile/${uid}`);

  profiles = () => this.db.collection('profiles');

  // *** User API ***

  user = (uid) => this.db.doc(`profile/${uid}/user/private`);
  // users = uid => this.db.doc(`users/${uid}`);

  // *** Message API ***

  message = uid => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection('messages');

  // *** Routes API ***

  route = uid => this.db.collection('routes').doc(`${uid}`).get();

  routes = () => this.db.collection('routes');

  // *** Routes API ***

  myRoute = uid => this.db.collection('routes').doc(uid);

  myRoutes = (authUser) => {
    // return this.geofirestore.collection('routes').where('userId', '==', authUser.uid);
    return this.db.collection('routes').where('d.userId', '==', authUser.uid);
  }

  addRoute = (route) => {
    const geocollection = this.geofirestore.collection('routes');
    const extension = route.image.substring("data:image/".length, route.image.indexOf(";base64"));

    // const originl_filename = 'original_'+uuidv4() + '.' + extension;
    const edited_filename = 'edited_'+uuidv4() + '.' + extension;
    const storageRef = firebase.storage().ref().child(`images/${edited_filename}`);

    return storageRef.putString(route.image, 'data_url')
      .then(function(snapshot) {
        return geocollection.add({
          ...route,
          image: snapshot.ref.fullPath,
          coordinates: new firebase.firestore.GeoPoint(route.lat, route.lng)
        });
      });
  }

  routesByPosition = (position, radius=50) => {
    // Create a GeoCollection reference
    const geocollection = this.geofirestore.collection('routes');
    // Create a GeoQuery based on a location
    return geocollection.near({ center: new firebase.firestore.GeoPoint(position.lat, position.lng), radius: process.env.REACT_APP_MAX_RADIUS / 1000 });
  }

  // Bucket url
  getDownloadUrl = (fullPath) => {
    return firebase.storage().ref().child(fullPath).getDownloadURL();
  }

  //Handle waiting to upload each file using promise
  // uploadImageAsPromise = (imageFile) => {
  //   return new Promise(function (resolve, reject) {
  //       var storageRef = firebase.storage().ref(fullDirectory+"/"+imageFile.name);

  //       //Upload file
  //       var task = storageRef.put(imageFile);

  //       //Update progress bar
  //       task.on('state_changed',
  //           function progress(snapshot){
  //               var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
  //               uploader.value = percentage;
  //           },
  //           function error(err){

  //           },
  //           function complete(){
  //               var downloadURL = task.snapshot.downloadURL;
  //           }
  //       );
  //   });
  // }
}

export default Firebase;
