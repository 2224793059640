import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

const SignOutListItem = ({ firebase, history }) => {
  const authUser = useContext(AuthUserContext);

  const signOut = () => {
    firebase.doSignOut();
    history.push('/')
  }
  return (
    <Fragment>
      {authUser ?
        <ListItem 
        button 
        key={'signout'}
        onClick={signOut}
      >
        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItem>
    :
      null
    }
    </Fragment>
  )
};

export default compose(
  withFirebase,
  withRouter,
)(SignOutListItem);

