import React, { Fragment, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { SnackbarContext } from './';

const SnackbarProvider = ({ children }) => {
  const [snackText, setSnackText] = useState('');
  const [open, setOpen] = useState(false);
  
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  const setSnack = (text) => {
    setSnackText(text);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      {/* <p>With Snackbar {snackText !== null ? snackText : 'no snack'}</p> */}
      <SnackbarContext.Provider value={{
        setSnack,
      }}>
        {children}
      </SnackbarContext.Provider>
        <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message={snackText}
      />
    </Fragment>
  );
};

export default SnackbarProvider;