import React from 'react';
import { compose } from 'recompose';
import { withEmailVerification } from '../../components/Session';
import { Container } from '@material-ui/core';

const Dashboard = () => {

  return (
    <Container maxWidth="md">
      <h1>Dashboard</h1>
      <p>TODO: Figure out what we want with the dashboard</p>
      <p>Maybe some overview of the last set routes and last climbed routes?</p>
      <p>Wanna haves: - last notifications invitations etc...</p>
    </Container>
  )
};

export default compose(
  withEmailVerification
)(Dashboard);
