import ExploreMyPositionPage from './pages/Explore';
import SignUpPage from './pages/SignUp';
import SignInPage from './pages/SignIn';
import PasswordForgetPage from './pages/PasswordForget';
import Dashboard from './pages/Home';
import SetRoute from './pages/MyRouteCreate';
import MyRoutes from './pages/MyRoutes';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import RouteDetail from './pages/RouteDetail';
import MyRouteDetail from './pages/MyRouteDetail';
import EditRoute from './pages/MyRouteEdit';

export const routes = [
  { path: '/dashboard', component: Dashboard, public: false },
  { path: '/my-routes', component: MyRoutes, public: false },
  { path: '/my-route/:uid', component: MyRouteDetail, public: false },
  { path: '/edit-route/:uid', component: EditRoute, public: false },
  { path: '/set-route', component: SetRoute, public: false },
  { path: '/signup', component: SignUpPage, public: true },
  { path: '/signin', component: SignInPage, public: true },
  { path: '/pw-forget', component: PasswordForgetPage, public: true },
  { path: '/privacy', component: Privacy, public: true },
  { path: '/contact', component: Contact, public: true },
  { path: '/route/:uid', component: RouteDetail, public: true },
  { path: '/', component: ExploreMyPositionPage, public: true },
];

export const authenticatedRoutes = routes.filter(route => !route?.public);
export const unauthenticatedRoutes = routes.filter(route => route?.public);
