import React, { useContext, useState } from 'react';
import {
  Button,
  IconButton
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { CurrentPositionContext } from '.';

const CurrentPositionButton = ({ inverted=false }) => {
  const [loading, setLoading] = useState();
  const positionProvider = useContext(CurrentPositionContext);
  const handleUsePosition = () => {
    setLoading(true);
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };
    const onChangeCurrent = ({coords}) => {
      setLoading(false);
      positionProvider.setCurrentPosition({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    };
    const onError = (error) => {
      positionProvider.setCurrentPositionLoading(false);
      setLoading(false);
      positionProvider.setCurrentPositionError(error.message);
    };
    const geo = navigator.geolocation;
    if (!geo) {
      positionProvider.setCurrentPositionError('Geolocation is not supported');
      return;
    }
    geo.getCurrentPosition(onChangeCurrent, onError, options);
  }
  return (
    <Tooltip title="Go to my current location">
      <IconButton
        edge="end"
        aria-label="use my location"
        onClick={handleUsePosition}
        color="inherit"
      >
        {loading ? <CircularProgress color={inverted ? 'white' : 'primary'} size={21} /> : <RoomIcon /> }
      </IconButton>
    </Tooltip>
  )
}

export default CurrentPositionButton;
