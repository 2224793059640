import React, { Fragment, useState } from 'react';

const AppContext = React.createContext(null);

const ContextProvider = ({ children }) => {
  const [sidebarState, setSidebarState] = useState(false);
  const [me, setMe] = useState();
  const [snack, setSnack] = useState({});
  const [geoCoordinate, setGeoCoordinate] = useState({});

  const onSetSideBarState = (state) => {
    setSidebarState(state);
  }

  const onSetMe = (me) => {
    setMe(me);
  }

  const onSetSnack = (snack) => {
    setSnack(snack);
  }

  return (
    <AppContext.Provider value={{
      sidebarState,
      onSetSideBarState,
      me,
      onSetMe,
      onSetSnack,
      snack,
      geoCoordinate,
      setGeoCoordinate
    }}
    >
      <Fragment>  
        {children}
      </Fragment>
    </AppContext.Provider>
  );
};

export {
  ContextProvider,
  AppContext as default,
};

