import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, IconButton } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "#fff",
    color: 'primary',
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open, defaultMessage, dialogTitle, inverted=false } = props;
console.log(dialogTitle)
console.log(defaultMessage)
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
      <List>

        <ListItem 
          button 
          key="facebook"
          component="a"
          href={`https://www.facebook.com/sharer/sharer.php?u=${defaultMessage}`}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <FacebookIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'facebook'} />
        </ListItem>

        <ListItem 
          button 
          key="twitter"
          component="a"
          href={`https://twitter.com/intent/tweet?text=${defaultMessage}`}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <TwitterIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'twitter'} />
        </ListItem>

        <ListItem 
          button 
          key="whatsapp"
          component="a"
          href={`whatsapp://send?text=${defaultMessage}`}
          target="_blank"
          data-action="share/whatsapp/share"
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <WhatsAppIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'WhatsApp'} />
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  defaultMessage: PropTypes.string
};

const ShareDialog = (props) => {
  const { inverted=false } = props;
  console.log(inverted)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton aria-label="share" onClick={handleClickOpen}>
        <ShareIcon style={{color: inverted ? 'white' : 'primary'}} />
      </IconButton>
      <SimpleDialog {...props} open={open} onClose={handleClose} />
    </Fragment>
  );
}

export default ShareDialog;

// https://www.facebook.com/sharer/sharer.php?u=streetboulders.com
// https://twitter.com/intent/tweet?text=streetboulders.com
// <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share">Share via Whatsapp</a>
