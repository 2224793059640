import React, {Fragment, useState, useEffect,  createRef } from 'react';
import ImageEditor from '@toast-ui/react-image-editor';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Grid, Button, Slider} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DetailsIcon from '@material-ui/icons/Details';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CheckIcon from '@material-ui/icons/Check';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { withFirebase } from '../Firebase';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const startHoldColor = '#dc004e';
const regularHoldColor = '#4caf50';
const endHoldColor = '#1976d2';
const strokeWidth = 3;
const fill = 'rgba(255,255,255,0.3)';
const XBounds = 10;
const YBounds = 12;
const startHold = {
  fill: fill,
  stroke: startHoldColor,
  strokeWidth: strokeWidth,
  width: 18,
  height: 18,
  left: 160,
  top: 160,
  isRegular: true,
  lockScalingX: true,
  lockScalingY: true,
  lockRotation: true,
  lockUniScaling: true,
  angle: 180,
  type: 'startHold',
  shape: 'triangle'
}
const regularHold = {
  fill: fill,
  stroke: regularHoldColor,
  strokeWidth: strokeWidth,
  rx: 10,
  ry: 10,
  left: 110,
  top: 110,
  isRegular: true,
  lockScalingX: true,
  lockScalingY: true,
  lockRotation: true,
  lockUniScaling: true,
  type: 'regularHold',
  shape:'circle'
}
const endHold = {
  fill: fill,
  stroke: endHoldColor,
  strokeWidth: strokeWidth,
  width: 18,
  height: 18,
  left: 100,
  top: 100,
  isRegular: true,
  lockScalingX: true,
  lockScalingY: true,
  lockRotation: true,
  lockUniScaling: true,
  type: 'endHold',
  shape: 'triangle'
}

const useStyles = makeStyles((theme) => ({
  appBarTop: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  avatarStartHold: {
    paddingTop: '3px',
    backgroundColor: '#eee',
    color: startHoldColor
  },
  avatarRegularHold: {
    backgroundColor: '#eee',
    color: regularHoldColor
  },
  avatarEndHold: {
    paddingBottom: '3px',
    backgroundColor: '#eee',
    color: endHoldColor
  }
}));

const holds = [
  startHold,
  endHold,
  regularHold
]
const Editor = ({ boulder, handleSave, handleCancel, firebase }) => {
  const editorRef = createRef();
  const classes = useStyles();
  const [holdmenuOpen, setHoldMenuOpen] = useState(false);
  const [startHoldCount, setStartHoldCount] = React.useState(0);
  const [imageSrc, setImageSrc] = React.useState(0);
  const [isEditorInstanceSet, setEditorInstanceIsSet] = React.useState(false);
  const [redoButtonDisabled, setRedoButtonDisabled] = React.useState(true);
  const [undoButtonDisabled, setUndoButtonDisabled] = React.useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = React.useState(true);
  const [activeObjectId, setActiveObjectId] = React.useState({});
  const [startHoldIds, setStartHoldIds] = useState([]);
  const [endHoldId, setEndHoldId] = useState(null);
  const [zoom, setZoom] = React.useState(0);
  const [ratio, setRatio] = React.useState(0);
  const [holdsAdded, setHoldsAdded] = React.useState(false);
  const [imageAdded, setImageAdded] = React.useState(false);
  const [holdsAddedCount, setHoldsAddedCount] = useState(0);
  const [imageEditorOpen, setImageEditorOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState();

  // useEffect(() => {
  //   if ( boulder && boulder.imageRef && imageEditorOpen === true) {
  //     firebase.getDownloadUrl(boulder.imageRef)
  //       .then(url => {
  //         console.log(url)
  //         setDownloadUrl(url)
  //         loadImageFromDownloadUrl(url)
  //       }).catch(err => {
  //         console.log(err)
  //       });
  //   }
  // }, [boulder, imageEditorOpen])

  useEffect(() => {

    // We only want to set the event listener once
    if (editorRef.current !== null && editorRef.current.getInstance() !== undefined && editorRef.current.getInstance() !== null && isEditorInstanceSet === false) {
      let canvasSize = {};

      setEditorInstanceIsSet(true);
      const editorInstance = editorRef.current.getInstance();
      console.log(editorInstance)
      // Get aspect ratio
      // TODO: use this for zoom function?
      const canvasWidth = editorInstance._graphics._canvas.width;
      const canvasHeight = editorInstance._graphics._canvas.height;
      // setRatio(canvasWidth/canvasHeight);

      editorInstance.changeCursor('grab');
      
      editorInstance.on({
        undoStackChanged: (length) => {
          console.log(editorInstance._graphics._canvas._objects)
          console.log(length);
          if(length === 1) {
            setImageAdded(true);
          
          }
        },
        objectMoved: (props) => {
          // TODO: Figur out a way to set a max bounding box
          // const canvasSize = editorInstance.getCanvasSize()
          // if (props.left >= canvasSize.width - XBounds) {
          //   editorInstance.setObjectProperties(props.id, {
          //     left: canvasSize.width - XBounds
          //   });
          // }
   
          // if (props.left <= XBounds) {
          //   editorInstance.setObjectProperties(props.id, {
          //     left: XBounds
          //   });
          // }
          // if (props.top >= canvasSize.height - YBounds) {
          //   editorInstance.setObjectProperties(props.id, {
          //     top: canvasSize.height - YBounds
          //   });
          // }
          // if (props.top <= YBounds) {
          //   editorInstance.setObjectProperties(props.id, {
          //     top: YBounds
          //   });
          // }
        },
        objectActivated: (obj) => {
          if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
            setDeleteButtonDisabled(false);
            setActiveObjectId(obj.id);
          } else {
            setDeleteButtonDisabled(true);
          }
        },
      });
    }
  },[editorRef])

  // TODO: Use this for edit mode
  // useEffect(() => {
  //   if (imageAdded && !holdsAdded && editorRef.current !== null) {
  //     addHolds()
  //   }
  // }, [imageAdded, editorRef, holdsAdded])

  const scaled = (zoom, originalValue) => {
    return Math.floor((zoom / 100) * originalValue);
  }

  // TODO: Fix zoom function
  const handleZoom = (event, zoom) => {
    // const imageEditorInst = editorRef.current.getInstance();
    // const canvasHeight = imageEditorInst._graphics._canvas.height;
    // const canvasWidth = imageEditorInst._graphics._canvas.height;
    setZoom(zoom);
  };

  const handleOnCancel = () => {
    setImageEditorOpen(false);
    if ( handleCancel ) {
      handleCancel();
    }
  }

  const toHolds = (objects) => {
    const holds = [];
    objects.forEach(obj => {
      const hold = {
        fill: obj.fill,
        stroke: obj.stroke,
        strokeWidth: obj.strokeWidth,
        left: obj.left,
        top: obj.top,
        isRegular: obj.isRegular,
        lockScalingX: obj.lockScalingX,
        lockScalingY: obj.lockScalingY,
        lockRotation: obj.lockRotation,
        lockUniScaling: obj.lockUniScaling,
        type: obj.type,
        shape:obj.shape
      }

      if (obj.shape === 'circle') {
        hold.rx = obj.rx;
        hold.ry = obj.ry;
      }
      
      if (objects.shape === 'triangle') {
        hold.width = obj.width;
        hold.height = obj.height;
      }

      holds.push(hold);
    });

    return holds;
  }

  const onHandleSave = () => {
    if ( handleSave ) {
      const imageEditorInst = editorRef.current.getInstance();
      const objects = imageEditorInst._graphics._canvas._objects
      const holds = toHolds(objects);
      const boulder = {
        original: imageSrc,
        edited:  imageEditorInst.toDataURL(),
        holds: holds,
      }
      handleSave(boulder);
      setImageEditorOpen(false);
    }
  }

  const handleClickOpen = () => {
    setHoldMenuOpen(true);
  };

  const handleClose = (value) => {
    setHoldMenuOpen(false);
  };

  const handleListItemClick = (value) => {
    switch (value) {
      case 'startHold':
        addStartHold();
        break;
      case 'endHold':
        addEndHold();
        break;
      case 'regularHold':
        addRegularHold();
        break;
    }
    setHoldMenuOpen(false);
  };
 
  const addHolds = () => {
    const imageEditorInst = editorRef.current.getInstance();
    // Some recursive promise magic
    for (let i = 0, p = Promise.resolve(); i < holds.length; i++) {
      p = p.then(_ => new Promise(resolve =>
        setTimeout(function () {
            console.log(i);
            return imageEditorInst.addShape(holds[i].shape, holds[i]).then((obj) => {
              resolve()
            })
        }, Math.random() * 10)
      ));
    }
    setHoldsAdded(true);
  }

  const addStartHold = () => {
    const imageEditorInst = editorRef.current.getInstance();
    imageEditorInst.addShape('triangle', startHold).then((obj) => {
      startHoldIds.push(obj.id);
      setStartHoldIds(startHoldIds);
    });
  }

  const addRegularHold = () => {
    const imageEditorInst = editorRef.current.getInstance();
    imageEditorInst.addShape('circle', regularHold);
  }

  const addEndHold = () => {
    const imageEditorInst = editorRef.current.getInstance();
    imageEditorInst.addShape('triangle', endHold).then(obj => {
      setEndHoldId(obj.id)
    });
  }

  const handleSetImageSrc = (src) => {
    const imageEditorInst = editorRef.current.getInstance();
    setImageSrc(src);
    imageEditorInst.loadImageFromURL(src, 'test')
  }

  // TODO: Fix this in edit mode
  const loadImageFromDownloadUrl = (url) => {
    console.log(url)
    const imageEditorInst = editorRef.current.getInstance();
    if(imageEditorInst !== null && url !== null) {
      console.log(imageEditorInst);
      imageEditorInst.loadImageFromURL(url, 'test').then(() => {
        console.log('done')
      })
    }
  }

  const resizeImage = (base64string, image, originalWidth, originalHeight) => {
    const maxWidth = 600;
    const maxHeight = 600;
    
    if (originalWidth > maxWidth || originalHeight > maxHeight) {
      let newWidth = null;
      let newHeight = null;
      if (originalWidth > maxWidth) {
        const ratio = maxWidth / originalWidth;   // get ratio for scaling image
        newHeight = originalHeight * ratio;       // Reset height to match scaled image
        newWidth =  originalWidth * ratio;        // Reset width to match scaled image
      }
      if (originalHeight > maxHeight) {
        const ratio = maxHeight / originalHeight; // get ratio for scaling image
        newWidth = originalWidth * ratio;         // Reset width to match scaled image
        newHeight = originalHeight * ratio;       // Reset height to match scaled image
      }
      // create an off-screen canvas
      const canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

      // set its dimension to target size
      canvas.width = newWidth;
      canvas.height = newHeight;

      // draw source image into the off-screen canvas:
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      const dataUrl = canvas.toDataURL();
      handleSetImageSrc(dataUrl);
    } else {
      handleSetImageSrc(base64string);
    }
  }

  const loadImage = files => {   
    let originalWidth = 0;
    let originalHeight = 0;

    const file = files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        originalWidth = image.width;
        originalHeight = image.height;
        resizeImage(event.target.result, image, originalWidth, originalHeight);
      };
    };
    reader.readAsDataURL(file);
  }

  const removeActiveObject = () => {
    if (startHoldIds.includes(activeObjectId)) {
      const holds = startHoldIds.filter(id => id !== activeObjectId);
      setStartHoldIds(holds);
    }
    if (endHoldId === activeObjectId) {
      setEndHoldId(null);
    }
    const imageEditorInst = editorRef.current.getInstance();
    // const activeObjectId = {};
    imageEditorInst.removeObject(activeObjectId);
  }

  // Crop menu action
  const onCropClick = () => {
    editorRef.current.startDrawingMode('CROPPER');
  };
  const onCropApply = () => {
    editorRef.current.crop(editorRef.current.getCropzoneRect()).then(function() {
      editorRef.current.stopDrawingMode();;
    });  
  }
  // Orientation menu action
  const onRotateClockwise = () => {
    const imageEditorInst = editorRef.current.getInstance();
    imageEditorInst.rotate(90);
  }
  const onRotateCounterClockwise = () => {
    const imageEditorInst = editorRef.current.getInstance();
    imageEditorInst.rotate(-90);
  }

  const startFreeDrawing = () => {
    const settings = {}; //getBrushSettings();
    editorRef.current.stopDrawingMode();
    editorRef.current.startDrawingMode('FREE_DRAWING', settings);
  }

  return (
    <Fragment>
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={() => setImageEditorOpen(true)}
      >
        {boulder && <CheckIcon /> } Add photo and set the route <InsertPhotoIcon />
      </Button>
      <Dialog fullScreen open={imageEditorOpen} onClose={handleOnCancel} TransitionComponent={Transition}>
        <Paper square style={{marginBottom: '3px'}}>
          <Grid container spacing={2}>
            <Grid item>
              <IconButton color="inherit" onClick={handleOnCancel} aria-label="close"><CloseIcon /></IconButton>
              <Tooltip title="Delete selected hold">
                <span><IconButton color="inherit" onClick={removeActiveObject} disabled={deleteButtonDisabled}><DeleteIcon /></IconButton></span>
              </Tooltip>
              <Tooltip title="Add hold">
                <span><IconButton color="inherit" onClick={handleClickOpen} disabled={!imageSrc}><AddCircleIcon /></IconButton></span>
              </Tooltip>
            </Grid>
            {/* TODO: Uncomment when zoom function is working */}
            {/* <Grid item>
              <IconButton color="inherit"><ZoomOutIcon /></IconButton>
            </Grid>
            <Grid item xs>
              <Slider disabled={!imageSrc} value={zoom} onChange={handleZoom} aria-labelledby="continuous-slider" />
            </Grid>
            <Grid item>
              <IconButton color="inherit"><ZoomInIcon /></IconButton>
            </Grid> */}
            <Grid item>
              <Button style={{paddingTop: '12px'}} autoFocus color="inherit" onClick={onHandleSave} disabled={!imageSrc}>save</Button>
            </Grid>
          </Grid>
        </Paper>

        {!imageSrc && 
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignContent="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <Dropzone onDrop={acceptedFiles => loadImage(acceptedFiles)} multiple={false}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button 
                      variant="contained" 
                      color="primary"
                      to="set-route"
                    >
                      Add photo
                    </Button>
                  </div>
                )}
              </Dropzone>
            </Grid>   
          </Grid> 
        }
          <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
          >
            <Grid item xs={12}>
            <ImageEditor
              className="tui-image-editor"
              // TODO: Figure out how i can use the max width in the zoom control
              // cssMaxHeight={window.innerHeight}
              // cssMaxWidth={window.innerWidth}
              selectionStyle={{
                cornerSize: 4,
                rotatingPointOffset: 0,
                borderColor: '#ff00ff',
                borderSize: 5,
                cornerShape: 'circle',
                cornerBackgroundColor: '#ff00ff',
                cornerColor: '#ff00ff',
                // cornerPadding: 10, {/* TODO: try more style options to get the perfect mobile experience */}
                padding: 14
              }}
              ref={editorRef}
              usageStatistics={false}
            />
            </Grid>
          </Grid>

        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={holdmenuOpen}>
          <DialogTitle id="simple-dialog-title">Add holds</DialogTitle>
          <List>
            <ListItem 
              autoFocus 
              button
              onClick={() => handleListItemClick('startHold')}
              key={1}
              disabled={startHoldIds.length >= 2}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatarStartHold}>
                  <DetailsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`Add start hold (${2 - startHoldIds.length} left)`} />
            </ListItem>
            
            <ListItem 
              button 
              onClick={() => handleListItemClick('endHold')} 
              key={2}
              disabled={endHoldId !== null}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatarEndHold}>
                  <ChangeHistoryIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`Add end hold (${endHoldId !== null ? 0 : 1} left)`} />
            </ListItem>

            <ListItem button onClick={() => handleListItemClick('regularHold')} key={3}>
              <ListItemAvatar>
                <Avatar className={classes.avatarRegularHold}>
                  <RadioButtonUncheckedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add regular hold" />
            </ListItem>
          </List>
        </Dialog>
      </Dialog>
    </Fragment>
  )
}

export default withFirebase(Editor);