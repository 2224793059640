import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { compose } from 'recompose';
import { Button, Grid, Container, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { withFirebase } from '../../components/Firebase';
import { withCurrentPosition } from '../../components/CurrentPosition';
import ClusterMap from '../../components/MapsComponent/ClusterMap';
import RouteList from '../../components/RouteList';
import RouteListCard from '../../components/RouteListCard';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const scrollToRef = (ref) => window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: 'smooth'})   

const Landing = ({ firebase, currentPosition }) => {

  // TODO: Load page based on url query parameters from 'shared links'

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const [routes, setRoutes] = useState(null);
  const [routesLoading, setRoutesLoading] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRoutes, setSelectedRoutes] = useState([null]);
  const scrollRef = useRef(null);

  const showSidebar = selectedRoutes !== null && selectedRoutes !== undefined && selectedRoutes[0] !== null;
  const executeScroll = () => scrollToRef(scrollRef)

  useEffect(() => {
    if (selectedRoute || selectedRoutes) {
      executeScroll()
    }
  }, [selectedRoutes, selectedRoute])

  useEffect(() => {
    if ( firebase && currentPosition ) {
      const firebaseListener = firebase
        .routesByPosition(currentPosition)
        .onSnapshot(snapshot => {
          if (snapshot.size) {
            let routes = [];
            snapshot.forEach(doc => {
              routes.push({ ...doc.data(), uid: doc.id })
            });
            setRoutes(routes.reverse());
            setRoutesLoading(false);
          } else {
            setRoutes(null);
            setRoutesLoading(false);
          }
        });
      return () => { firebaseListener() };
    }
  }, [firebase, currentPosition])

  return (
    <Container maxWidth={false} style={{marginTop: '0px', paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px'}}>
      <Grid
        spacing={0}
        container
        direction="row-reverse"
        alignItems="flex-end"
        justify="space-between"
      >
        <Grid item xs={showSidebar ? 12 : 12} md={showSidebar ? 9 : 12} xl={showSidebar ? 10 : 12}>
          <ClusterMap
            routes={routes}
            onMarkerClick={(selectedRoute) => {setSelectedRoutes(null); setSelectedRoute(selectedRoute)}}
            onClusterClick={(selectedRoutes) => {setSelectedRoute(null); setSelectedRoutes(selectedRoutes)}}
          />
        </Grid>
        
        <Grid item xs={showSidebar ? 12 : false} md={showSidebar ? 3 : false} xl={showSidebar ? 2 : false} ref={scrollRef} style={{
          height: matches ? `calc(100vh -  69px)`: 'auto',
          zIndex: 1,
          maxHeight: matches ? '100vh' : 'auto',
          background: '#fff',
          backgroundColor: 'transparent',
          background: 'transparent'
        }}>
          <Paper square elevation={3} style={{zIndex: 3, height: '100%', overflowY: matches ? 'scroll' : 'visible',}}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            className={classes.cardGrid}
            ref={scrollRef}
          >
            {showSidebar && selectedRoutes.map(route => (
              <RouteListCard route={route} key={route.uid} />
            ))}
            {/* <RouteList routes={selectedRoutes} /> */}
          </Grid> 
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
};

export default compose(
withFirebase,
withCurrentPosition,
)(Landing);
