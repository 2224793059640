import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Container,
  Grid,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
 } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { withFirebase } from '../../components/Firebase';
import { withEmailVerification } from '../../components/Session';

const MyRoutes = ({ firebase }) => {
  const [routes, setRoutes] = useState(null);
  const [databaseLoading, setLoading] = useState(true);
  const [deletePromptOpen, setDeletePromptOpen] = React.useState(false);
  const [toBeDeletedUid, setToBeDeletedUid] = React.useState(null);
  const [limit, setLimit] = React.useState(30);
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    if (firebase && authUser) {
      const firebaseListener = firebase
        .myRoutes(authUser)
        .orderBy('d.name', 'desc')
        .limit(limit)
        .onSnapshot(snapshot => {
          if (snapshot.size) {
            let routes = [];
            snapshot.forEach(doc => {
              routes.push({ ...doc.data().d, uid: doc.id })
            });
            setRoutes(routes.reverse());
            setLoading(false);
          } else {
            setRoutes(null);
            setLoading(false);
          }
        });
        return () => firebaseListener();
    }
  }, [firebase, authUser])

  const onRemoveRoute = uid => {
    setToBeDeletedUid(uid)
    setDeletePromptOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeletePromptOpen(false);
  }; 

  const onNextPage = () => {
    setLimit(limit + 5);
  };

  const handleDeleteConfirm = () => {
    if (toBeDeletedUid) {
      firebase.myRoute(toBeDeletedUid).delete();
    }
    setDeletePromptOpen(false);
    setToBeDeletedUid(null);
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="right"
        justify="center"
      >
        <Grid item xs={12}>
          <h1>My routes</h1>
        </Grid>  
      </Grid> 
   
      {authUser ? 
        <Fragment>
          {databaseLoading && <div>Loading ...</div>}

          {routes && (
            <List>
              {routes.map(route => {
                return (
                  <ListItem
                    key={route.uid}
                    button
                    component={Link}
                    to={`/my-route/${route.uid}`}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={route.name} secondary={route.grade} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit"
                        component={Link}
                        to={`/edit-route/${route.uid}`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete"
                        onClick={() => onRemoveRoute(route.uid)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
           </List>
          )}


          {!databaseLoading && routes && (
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justify="center"
              style={{marginTop: '40px', marginBottom: '40px'}}
            >
              <Grid item xs={12}>
              <Button type="button" onClick={onNextPage}>
                More
              </Button>
                {/* <Button 
                  variant="contained" 
                  color="primary"
                  onClick={createRoute}
                  disabled={isInvalid}
                  style={{marginTop: '40px'}}
                >
                  Save route
                </Button> */}
              </Grid>
            </Grid>
            
          )}
          {!databaseLoading && !routes && <div>There are no routes ...</div>}
        </Fragment>
      :
        ''
      }

      <Dialog
        open={deletePromptOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete route?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this route?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default compose(
  withFirebase,
  withEmailVerification
)(MyRoutes);
